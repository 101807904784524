import {useFormik, FormikProvider} from 'formik'
import styles from './gated-page-template.module.scss'
import {useState} from "react";
import LoginForm from "./LoginForm/LoginForm";
import axios from "axios";

import formData from 'src/formData.json'
import {IField} from "../../interfaces";
import {FormField, PageHeader} from "../../components";
import BenefitsList from "../BenefitsList/BenefitsList";
//import BenefitsList from '../__backup__BenefitsList/BenefitsList';
import {useTranslation} from "react-i18next";

import { API_HOST, isDevEnv } from 'src/helpers'
import {userEmailContext} from "../../helpers/context";



const GatedPageTemplate = () => {
    const [email, setEmail] = useState<any>(null);


    const [user, setUser] = useState<any>(null);
    const [error, setError] = useState<string | null>(null);
    const [isUserInactive, setIsUserInactive] = useState<boolean>(false);

    const { t } = useTranslation()

    const formik = useFormik<any>({
        initialValues: {
            email: '',
            dateOfBirth: ''
        },
        onSubmit: async (values, {setFieldValue: any}) => {
            localStorage.removeItem('personId');
            localStorage.removeItem('dateOfBirth');
            localStorage.removeItem('first_name');
            localStorage.removeItem('last_name');
            localStorage.removeItem('email');



            setError(null);
            axios
                .get(`${API_HOST}v1/persons/email/sign-in?email=${encodeURIComponent(values.email.trim())}&dateOfBirth=${values.dateOfBirth}`, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                .then((response) => {
                    console.log('get',response,response?.data, response?.data?.id);
                    setUser(response?.data);
                    localStorage.setItem('email',values.email.trim());
                    localStorage.setItem('personId',response?.data?.id);
                    localStorage.setItem('dateOfBirth',formatDate(response?.data?.date_of_birth));
                    localStorage.setItem('first_name',response?.data?.first_name);
                    localStorage.setItem('last_name',response?.data?.last_name);
                }).catch((err) => {
                console.log(err)
                if(err?.response?.data?.error === 'ABC is not found or inactive') {
                    setIsUserInactive(true)
                    return
                }
                setError(t('The user is not exist'));
            });
        },
    })

    function formatDate(dateString?: string) {
      if (!dateString) {
        return ''
      }
      const date = new Date(dateString)
      return date.toISOString().slice(0, 10)
    }

    const showBenefitsList = (
      !!user
      && user?.associatedBenefitContracts
        // FILTER PAID FAMILY LEAVE ABC, this ABC is not allowed to see the benefits
        .filter((item: any) => item.id.associatedBenefitContract.id !== '4853a62a-898e-4eb8-b723-1e40c37e66b2')
        .some((item: any) => !item.inactive)
    ) || isDevEnv

    if (showBenefitsList) {
        return (
            <div className={styles.wrapper}>
                <PageHeader/>
                <BenefitsList userId={user?.id}/>
                <div style={{paddingTop: 50}}>
                </div>
            </div>
        )
    } else {
        const isInactive = user?.associatedBenefitContracts
        .filter((item: any) => item.id.associatedBenefitContract.id !== '4853a62a-898e-4eb8-b723-1e40c37e66b2')
            .some((item: any) => item.inactive);
        return (
            <userEmailContext.Provider value={{ email, setEmail }}>
            <FormikProvider value={formik}>
                <LoginForm onNext={(v: any) => {
                    formik.submitForm()
                }} error={error} user={user} isUserInactive={isUserInactive || isInactive}>
                    <>
                        {(formData.steps[0].fields as IField[])
                            .filter((f: IField) => !f.disabled)
                            .map((f: IField) => (
                                <FormField
                                    key={f.name}
                                    {...f}
                                    onChange={(value) => {
                                    }}
                                />
                            ))}
                    </>
                </LoginForm>
            </FormikProvider>
            </userEmailContext.Provider>
        )
    }
}

export default GatedPageTemplate
